<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCar/parkspace" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          export: true,
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            title: "基本信息",
            children: [{
                type: "seq",
                width: 60
              },
              {
                type: "checkbox",
                width: 60,
              },
              {
                field: "feeMonth",
                title: "计费期",
                minWidth: 130,
                editRender: {
                  name: "$input",
                  props: {
                    maxlength: 24,
                    placeholder: "请输入计费期"
                  }
                }
              },
              {
                field: "configId",
                title: "费用项",
                minWidth: 150,
                editRender: {
                  name: "$select",
                  props: {
                    placeholder: "请输入费用项"
                  }
                }
              },
              {
                field: "feeTypeId",
                title: "收费类型",
                minWidth: 100,
                editRender: {
                  name: "$select",
                  optionTypeCode: "costType",
                  props: {
                    placeholder: "请输收费类型"
                  }
                }
              },
            ]
          },
          {
            title: "费用信息",
            children: [{
                field: "receivableAmount",
                title: "应收金额",
                minWidth: 100,
                editRender: {
                  name: "$input",
                  props: {
                    type: "float",
                    digits: 3,
                    min: 0,
                    max: 99999,
                    maxlength: 24,
                    placeholder: "请输入应收金额"
                  }
                }
              },
              {
                field: "discountDetail",
                title: "优惠金额",
                minWidth: 100,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "0.000"
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    type: "float",
                    digits: 3,
                    min: 0,
                    max: 99999,
                    maxlength: 24,
                    placeholder: "请输入优惠金额"
                  }
                }
              },
              {
                field: "receivedAmount",
                title: "实收金额",
                minWidth: 100,
                formatter: ({ cellValue, row, column }) => {
                  if (!cellValue) {
                    return "/"
                  } else {
                    return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
                  }
                },
                editRender: {
                  name: "$input",
                  props: {
                    type: "float",
                    digits: 3,
                    min: 0,
                    max: 99999,
                    maxlength: 24,
                    placeholder: "请输入实收金额"
                  }
                }
              },
              {
                field: "startTime",
                title: "费用开始时间",
                minWidth: 130,
                editRender: {
                  name: "$input",
                  props: {
                    type: "date",
                    valueFormat: "yyyy-MM-dd HH:mm:ss",
                    placeholder: "请选择费用开始时间"
                  }
                }
              },
              {
                field: "endTime",
                title: "费用结束时间",
                minWidth: 130,
                editRender: {
                  name: "$input",
                  props: {
                    type: "date",
                    valueFormat: "yyyy-MM-dd HH:mm:ss",
                    placeholder: "请选择费用结束时间"
                  }
                }
              },
            ]
          },
          // {
          //   title: "折扣信息",
          //   children: [{
          //       field: "discountName",
          //       title: "打折名称",
          //       minWidth: 100,
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           maxlength: 24,
          //           placeholder: "请输入打折名称"
          //         }
          //       }
          //     },
          //     {
          //       field: "discountPrice",
          //       title: "折扣金额",
          //       minWidth: 100,
          //       formatter: ({ cellValue, row, column }) => {
          //         if (!cellValue) {
          //           return "/"
          //         } else {
          //           return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
          //         }
          //       },
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           type: "float",
          //           min: 0,
          //           max: 99999,
          //           
          //           maxlength: 24,
          //           placeholder: "请输入折扣金额"
          //         }
          //       }
          //     },
          //     {
          //       field: "discountDetail",
          //       title: "折扣明细",
          //       minWidth: 160,
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           maxlength: 24,
          //           placeholder: "请输入折扣明细"
          //         }
          //       }
          //     },
          //   ]
          // },
          // {
          //   title: "违约金信息",
          //   children: [{
          //       field: "breachPrice",
          //       title: "违约金",
          //       minWidth: 100,
          //       formatter: ({ cellValue, row, column }) => {
          //         if (!cellValue) {
          //           return "/"
          //         } else {
          //           return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
          //         }
          //       },
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           type: "float",
          //           min: 0,
          //           max: 99999,
          //           
          //           maxlength: 24,
          //           placeholder: "请输入违约金"
          //         }
          //       }
          //     },
          //     {
          //       field: "breachTime",
          //       title: "违约起始时间",
          //       minWidth: 130,
          //       editRender: {
          //         name: "$input",
          //         props: {
          //           type: "date",
          //           valueFormat: "yyyy-MM-dd HH:mm:ss",
          //           placeholder: "请选择违约起始时间"
          //         }
          //       }
          //     },
          //   ]
          // },
          {
            fixed: "right",
            title: "操作",
            width: 100,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                status: "danger",
                label: "删除",
                name: 'delete',
                eventName: "removeEvent"
              }]
            }
          }
        ],
        editConfig: {
          // enabled: false
        },
        cellClassName: ({ row, column }) => {
          if (column.property === 'receivableAmount') {
            return 'blue'
          }
          if (column.property === 'discountDetail') {
            return 'red'
          }
          if (column.property === 'receivedAmount') {
            return 'green'
          }
          return null
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "feeMonth",
            title: "计费期",
            itemRender: {
              name: "$input",
              props: {
                type: "month",
                labelFormat: "yyyy-MM",
                valueFormat: "yyyy-MM",
                maxlength: 24,
                placeholder: "请选择计费期"
              }
            }
          },
          {
            field: "feeTypeId",
            title: "收费类型",
            itemRender: {
              name: "$select",
              optionTypeCode: "costType",
              props: {
                placeholder: "请输收费类型"
              }
            }
          },
          {
            field: "startTime",
            title: "费用开始时间",
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择费用开始时间"
              }
            }
          },
          {
            field: "endTime",
            title: "费用结束时间",
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd 23:59:59",
                placeholder: "请选择费用结束时间"
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property]) {
                    data[property] = this.$XEUtils.toDateString(data[property], "yyyy-MM-dd 23:59:59");
                  }
                }
              }
            }
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          feeMonth: "",
          feeTypeId: "",
          startTime: "",
          endTime: "",
          status: "0"
        },
      },
    };
  },

  methods: {
    ...mapActions(['findByEventService', 'submitEventService']),
    handleNodeClick(treeData, node) {
      let { data } = this.searchOptions;
      data['parentType'] = node.type;
      data['parentId'] = node.id;
      data['type'] = "car";
      this.getFeeConfigLists(node);
      this.$refs.xGrid.getLists();
    },

    // 获取费用项
    getFeeConfigLists(node) {
      this.findByEventService({
          service: "/fee/fPayFeeConfig",
          params: {
            parentId: node.communityId || node.id
          }
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.$Tools.buildDataToGrid(this.gridOptions.columns, data, "configId", { value: "id", label: "feeName" });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
